<template>
  <div>

    <h5 v-if="label && labelTop" class="mb-2" :style="`color : ${labelColor || 'var(--wsMIDDLE)' }; font-size: 13px; font-weight: 500 `" >  {{  $t(label)  }} </h5>
    <div class="d-flex align-center justify-space-between">

      <h5 :class="[{'font-weight-regular' : light}]" :style=" labelColor ? `color : ${labelColor}` : null " v-if="label && !large && !labelTop"> {{ $t(label) }}</h5>
      <h4 :class="[{'font-weight-regular' : light}]" :style=" labelColor ? `color : ${labelColor}` : null " v-if="label && large && !labelTop"> {{ $t(label) }}</h4>

      <v-sheet color="transparent" width="100%">
        <ft-select
            v-model="paramValue"
            :items="items"
            :icon-color="wsATTENTION"
            style="width: 100%"
        >
          <template #default="{text}">

            <v-sheet
                :class="[{'justify-end' : !labelTop} , {'justify-space-between' : labelTop}]"
                class="d-flex align-center"
                width="100%"
            >

              <h5 :class="[{'ml-2' : !labelTop} , {'text-right' : !labelTop}]" class="shorten-text" :style=" valueColor ? `color : ${valueColor}` : null " v-if="!large"> {{ text || $t(nullText) }} </h5>
              <h4 :class="[{'ml-2' : !labelTop} , {'text-right' : !labelTop}]" class="shorten-text" :style=" valueColor ? `color : ${valueColor}` : null " v-if="large">  {{ text || $t(nullText) }} </h4>

              <div class="d-flex align-center" >
                <v-icon class="ml-1"  v-if="clearable && paramValue"  @click.stop="paramValue = null"  :color="wsACCENT" icon >mdi-close</v-icon>
                <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
              </div>

            </v-sheet>

          </template>

        </ft-select>
      </v-sheet>

    </div>

  </div>
</template>

<script>
export default {
  name: "aParameterSlider",
  props : {
    value : {},
    label : {
      type : String,
      default : ''
    },
    items : {
      type : Array,
      default() { return [] }
    },
    large : {
      type : Boolean,
      default : false
    },
    labelColor : {
      type : String,
    },
    valueColor : {
      type : String,
    },
    nullText : {
      type : String,
    },
    labelTop : {
      type : Boolean,
      default : false
    },
    clearable : {
      type : Boolean,
      default : false
    },
    light : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      paramValue : '',
    }
  },
  watch : {
    value() {
      if ( this.value !== this.paramValue ) {
        this.paramValue = this.value
      }
    },
    paramValue() {
      if ( this.value !== this.paramValue ) {
        this.$emit('input',this.paramValue)
      }
    }
  },
  mounted() {
    this.paramValue = this.value
  }
}
</script>

<style scoped>

</style>