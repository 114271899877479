const actions = {

    // business admin
    async GET_WEBINARS({dispatch,rootState} = 'fine') {

        console.log(dispatch,rootState)
        alert('GET WEBINARS WORKING')
        // return await dispatch('AJAX_SEND_NEW',
        //     {
        //         route:`news/admin`,
        //         method:'GET',
        //         business : rootState.business.selectedBusiness.alias,
        //     });
    },

}

export default {
    namespaced:true,
    actions,
}