const state = {

    darkMode : false,

    variables : {
        wsWHITE           : "#ffffff",
        wsACCENT          : "#567186",
        wsDARK            : "#567186",
        wsDARKER          : "#283E4F",
        wsDARKLIGHT       : "#94b3c7",
        wsBACKGROUND      : "#DEE8EE",
        wsLIGHTACCENT     : "#D7E5F0",
        wsLIGHTCARD       : "#F2F8FB",
        wsSUCCESS         : "#7AB971",
        wsLIGHTSUCCESS    : "#DDF5CD",
        wsATTENTION       : "#ED8A58",
        wsWARNING         : "#fe4b4b",
        wsDASHBACKGROUND  : "#DCE8EF",
        wsSUCCESSDARK     : "#44655b",
        wsMIDDLE          : "#4F5A6E"
    },

    // darkModeColors : {
    //     wsWHITE           : "#ffffff",
    //     wsACCENT          : "#737373",
    //     wsDARK            : "#737373",
    //     wsDARKER          : "#393939",
    //     wsDARKLIGHT       : "#CBCBCB",
    //     wsBACKGROUND      : "#EBEBEB",
    //     wsLIGHTACCENT     : "#EBEBEB",
    //     wsLIGHTCARD       : "#FDFDFD",
    //     wsSUCCESS         : "#7AB971",
    //     wsLIGHTSUCCESS    : "#DDF5CD",
    //     wsATTENTION       : "#ca183d",
    //     wsWARNING         : "#fe4b4b",
    //     wsDASHBACKGROUND  : "#DCE8EF",
    //     wsSUCCESSDARK     : "#44655b",
    //     wsMIDDLE          : "#4F5A6E"
    // },

    darkModeColors : {
        wsWHITE           : "#201b25",
        wsACCENT          : "#EBEBEB",
        wsDARK            : "#EBEBEB",
        wsDARKER          : "#e3e2e2",
        wsDARKLIGHT       : "#ffffff",
        wsBACKGROUND      : "#342d3c",
        wsLIGHTACCENT     : "#737373",
        wsLIGHTCARD       : "#40335e",
        wsSUCCESS         : "#5A8853",
        wsLIGHTSUCCESS    : "#5A8853",
        wsATTENTION       : "#5014cc",
        wsWARNING         : "#92002C",
        wsDASHBACKGROUND  : "#142833",
        wsSUCCESSDARK     : "#5A8853",
        wsMIDDLE          : "#FFFFFF"
    },

    // darkModeColors : {
    //     wsWHITE           : "#283E4F",
    //     wsACCENT          : "#E0E4E7",
    //     wsDARK            : "#E0E4E7",
    //     wsDARKER          : "#FFFFFF",
    //     wsDARKLIGHT       : "#FFFFFF",
    //     wsBACKGROUND      : "#142833",
    //     wsLIGHTACCENT     : "#567186",
    //     wsLIGHTCARD       : "#73899a",
    //     wsSUCCESS         : "#5A8853",
    //     wsLIGHTSUCCESS    : "#5A8853",
    //     wsATTENTION       : "#CC6C3B",
    //     wsWARNING         : "#92002C",
    //     wsDASHBACKGROUND  : "#142833",
    //     wsSUCCESSDARK     : "#5A8853",
    //     wsMIDDLE          : "#FFFFFF"
    // },

    lightModeColors : {
        wsWHITE           : "#ffffff",
        wsACCENT          : "#567186",
        wsDARK            : "#567186",
        wsDARKER          : "#283E4F",
        wsDARKLIGHT       : "#94b3c7",
        wsBACKGROUND      : "#DEE8EE",
        wsLIGHTACCENT     : "#D7E5F0",
        wsLIGHTCARD       : "#F2F8FB",
        wsSUCCESS         : "#7AB971",
        wsLIGHTSUCCESS    : "#DDF5CD",
        wsATTENTION       : "#ED8A58",
        wsWARNING         : "#fe4b4b",
        wsDASHBACKGROUND  : "#DCE8EF",
        wsSUCCESSDARK     : "#44655b",
        wsMIDDLE          : "#4F5A6E"
    },


}
export default {
    namespaced:true,
    state
}