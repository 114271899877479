<template>
<div  style="position: fixed; top: 0; left: 0;  z-index : 999999999999999999; transition: width 0.3s ease" :style="`width : ${expand ? 280 : 50}px`">
  <v-sheet @click="expand = !expand" height="56" class="d-flex py-3 pl-6 pointer" color="transparent" v-ripple>

  </v-sheet>
  <v-expand-transition>
    <v-sheet style="position: fixed; top: 56px; bottom : 0; left: 0; width: 280px;  z-index : 999999999999999999 "
             class="pa-6 overflow-y-auto" v-if="expand">
      <ws-super-admin-color-panel />
    </v-sheet>
  </v-expand-transition>

</div>
</template>

<script>
import wsSuperAdminColorPanel from "@modules/dev/wsSuperAdminColorPanel.vue";
export default {
  name: "wsDevPanel",
  components : {
    wsSuperAdminColorPanel
  },
  data() {
    return {
      expand : false
    }
  }
}
</script>

<style scoped>

</style>