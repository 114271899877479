<template>
<div>
<h3>WS Color Editor</h3>

  <ws-button @click="changeFont" label="changeFont" />
  <ws-switch
      @input="toggleDarkMode"
      placeholder="DarkMode"
      v-model="$store.state.colors.darkMode"
  />
  <ws-text-field
      v-for="(value,color) in $store.state.colors.variables" :key="color"
      @change="updateColors(color , $event)"
      :value="$store.state.colors.variables[color]"
      :label="color"
      class="mt-5"
  >
    <template #prepend-inner>
      <v-icon :color="value">mdi-circle</v-icon>
    </template>
  </ws-text-field>
</div>
</template>

<script>
export default {
  name: "wsSuperAdminColorPanel",
  methods : {
    updateColors(color , value) {
      this.$store.state.colors.variables[color] = value

      this.$store.state.colors.variables = this.COPY(this.$store.state.colors.variables)
      document.documentElement.style.setProperty(`--${color}`, value);

    },
    toggleDarkMode(value) {
      this.$store.state.colors.variables =  this.COPY(value ?
          this.$store.state.colors.darkModeColors
          : this.$store.state.colors.lightModeColors
      )
      Object.keys(this.$store.state.colors.variables).forEach(color => {
        document.documentElement.style.setProperty(`--${color}`, this[color]);
      })
    },
    changeFont() {
      document.documentElement.style.setProperty(`--app-font-family`, 'Courier');
    }
  }
}
</script>

<style scoped>

</style>